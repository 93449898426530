import React, { useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { i18n } from '@superfuds/sf-app';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import SVG from 'react-inlinesvg';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  ListItem,
  List,
  Divider,
  useMediaQuery,
} from '@mui/material';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Creators as AuthCreators } from '@reducers/Auth';
import { useWindowSize } from '@hooks/use-windows-size.hook';

import logoSF from '@assets/svg/superfuds_full_logo.svg';
import leaf from '@assets/svg/LogoSF.svg';
import { CategoriesHeader } from '@containers/CategoriesHeader/categoriesHeader.container';
import { MenuButton, SearchBar, CartButton, UserInfo, UbicationButton } from '@components/index';
import './style.scss';

const renderSuperfudsLogo = () => (
  <SVG
    id="TemplatesHeaderSuperfudsLogo"
    className="svg"
    width="200"
    alt="Logo superfuds"
    src={logoSF}
  />
);

const logoMobile = () => (
  <SVG
    id="TemplatesHeaderSuperfudsLogo"
    className="svg"
    alt="Logo superfuds"
    width="30"
    height="30"
    src={leaf}
  />
);

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: 10,
  marginLeft: 10,
  width: '50%',
  [theme.breakpoints.up('xs')]: {
    marginLeft: theme.spacing(3),
    width: '70%',
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(3),
    width: '45%',
  },
}));

export const Header = ({
  toggleLocationModal,
  shoppingCartItemsQty,
  toggleDrawer,
  setAuthModal,
  token,
  user,
  toggleSideCart,
  categories,
  drawerIsOpen,
}) => {
  useEffect(() => { }, [categories]);
  const isTablet = useMediaQuery('(max-width: 899px)');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const scrollThreshold = windowHeight * 0;

      if (scrollPosition > scrollThreshold) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { categoryId, subCategoryId } = useParams();
  const [activeCategory, setActiveCategory] = useState(
    categories.find((category) => category.id === parseInt(categoryId, 10)) || undefined,
  );

  const logoRender = (value = 0) => (
    <Box
      sx={{ display: { xs: 'flex', md: 'none' }, padding: value }}
      onClick={() => history.push('/')}
    >
      {logoMobile()}
    </Box>
  );
  const OptionsAccount = [
    {
      isUserComplete: false,
      option: i18n.getText('header.menu-list-Account.option_3'),
      onClick: () => history.push('/register'),
      id: 'TemplatesHeaderUserMenuAccountOption-CompleteUserData',
    },
    {
      isUserComplete: -1,
      option: i18n.getText('header.menu-list-Account.option_2'),
      onClick: () => {
        history.push('/pedidos');
      },
      id: 'TemplatesHeaderUserMenuAccountOption-Logout',
    },
    {
      isUserComplete: -1,
      option: i18n.getText('header.menu-list-Account.option_5'),
      onClick: () => {
        dispatch(AuthCreators.logoutRequest());
      },
      id: 'TemplatesHeaderUserMenuAccountOption-Logout',
    },
  ];
  const OptionsLogin = [
    {
      index: 1,
      option: i18n.getText('header.menu-list-Login.option_1'),
      onClick: () => dispatch(AuthCreators.openBToBModal()),
      id: 'TemplatesHeaderUserMenuLoginOption-OpenBToCModal',
    },
    {
      index: 2,
      option: i18n.getText('header.menu-list-Login.option_2'),
      onClick: () => dispatch(AuthCreators.openBToBModal()),
      id: 'TemplatesHeaderUserMenuLoginOption-openBToBModal',
    },
  ];

  const handleChangeCategory = (category) => {
    setActiveCategory(category);
    history.push(`/categoria/${category.id}`);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar className={`header ${isFixed ? 'fixed' : ''}`} position="sticky">
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            style={{
              cursor: 'pointer',
              marginTop: 12,
              paddingRight: width <= 1480 ? 10 : 0 || (token && 65),
            }}
            onClick={() => history.push('/')}
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            {width <= 1480 ? logoMobile() : renderSuperfudsLogo()}
          </Typography>
          {token && logoRender(1)}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <MenuButton onClick={toggleDrawer} drawerIsOpen={drawerIsOpen} />
          </Box>
          <Search>
            <SearchBar placeholder={i18n.getText('header.search-placeholder')} user={user} />
          </Search>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', lg: 'flex' } }}>
            {token && (
              <div className="toolbar__ubication-button-container">
                <UbicationButton
                  id="TemplatesHeaderUbicationButton"
                  onclick={toggleLocationModal}
                />
              </div>
            )}
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {!token && (
              <div
                id="UserInfoButtonLogin"
                className="button-login cursor-pointer"
                onClick={() => history.push('/proveedor')}
              >
                <div className="right-column">
                  <Typography className="name-text" variant="subtitle1">
                    Comienza a vender
                  </Typography>
                </div>
              </div>
            )}
            <div
              className="toolbar__user-info-container"
              style={{
                width: (token && '100%') || 'auto',
              }}
            >
              <UserInfo
                OptionsAccount={OptionsAccount}
                OptionsLogin={OptionsLogin}
                myAccountText={i18n.getText('header.my-account-text')}
                titleButton={i18n.getText('header.title-button-login')}
                setAuthModal={setAuthModal}
                name={user.name}
                picture={user.avatar}
                token={token}
                clientType={user.clientType}
                user={user}
              />
            </div>
          </Box>
          {!token && logoRender()}
          {token && (
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
              <div className="toolbar__cart-button-container">
                <CartButton
                  isCartButtonEnabled
                  id="TemplatesHeaderCartButton"
                  quantity={shoppingCartItemsQty}
                  toggleSidecart={toggleSideCart}
                />
              </div>
            </Box>
          )}
        </Toolbar>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {location.pathname.includes('categoria') && (
            <Box
              sx={{
                flexGrow: 12,
                flexDirection: 'row',
                display: { xs: isTablet ? 'flex' : 'none' },
                width: '100%',
              }}
            >
              <CategoriesHeader
                categories={categories}
                onCategoryChangeHandler={handleChangeCategory}
                activeCategory={activeCategory}
              />
            </Box>
          )}
          <Box
            sx={{
              flexGrow: 12,
              flexDirection: 'row',
              display: { xs: isTablet ? 'flex' : 'none' },
            }}
          >
            {token && (
              <div
                className="toolbar__ubication-button-container"
                style={{
                  background: 'transparent',
                  width: '100%',
                  padding: '0px 20px 5px',
                }}
              >
                <UbicationButton
                  justifyContent="left"
                  id="TemplatesHeaderUbicationButton"
                  onclick={toggleLocationModal}
                />
              </div>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: isTablet ? 'none' : 'flex',
            flexDirection: 'row',
          }}
        >
          {categories.length > 0 && (
            <Box
              component="div"
              className="menu"
              sx={{
                flexGrow: 12,
                alignSelf: 'flex-start',
                display: { xs: 'flex', md: 'flex' },
              }}
            >
              <List>
                <ListItem onClick={() => history.push('/todas-brands')}>
                  <Typography
                    noWrap
                    className={`p ${location.pathname === '/todas-brands' && 'active'}`}
                  >
                    Todas las marcas
                  </Typography>
                </ListItem>
                {categories?.map((e) => {
                  return (
                    <ListItem key={e.name}>
                      <Typography
                        noWrap
                        onClick={() => history.push(`/categoria/${e.id}`)}
                        className={`p ${e.id === parseInt(categoryId, 10) ? 'active' : ''}`}
                      >
                        {e.name}
                        {e.subcategories.length > 0 && (
                          <KeyboardArrowDownIcon
                            style={{
                              position: 'relative',
                              top: 8,
                            }}
                          />
                        )}
                      </Typography>
                      {e.subcategories.length > 0 && (
                        <List className="submenu">
                          {e.subcategories.map((item) => (
                            <div key={item.name}>
                              <ListItem
                                onClick={() => history.push(`/categoria/${e.id}/${item.id}`)}
                              >
                                <Typography
                                  className={`p ${item.id === parseInt(subCategoryId, 10) ? 'active' : ''
                                    }`}
                                >
                                  {item.name}
                                </Typography>
                              </ListItem>
                              <Divider />
                            </div>
                          ))}
                        </List>
                      )}
                    </ListItem>
                  );
                })}
                <ListItem
                  onClick={() =>
                    window.open('https://superblog.superfuds.com', '_blank', 'noopener,noreferrer')
                  }
                >
                  <Typography noWrap className="p blog">
                    Süper Blog
                  </Typography>
                </ListItem>
              </List>
            </Box>
          )}
        </Box>
      </AppBar>
    </Box>
  );
};

Header.defaultProps = {
  token: null,
};

Header.propTypes = {
  toggleLocationModal: PropTypes.func.isRequired,
  shoppingCartItemsQty: PropTypes.number.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  setAuthModal: PropTypes.func.isRequired,
  toggleSideCart: PropTypes.func.isRequired,
  drawerIsOpen: PropTypes.bool.isRequired,
  token: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    isEmployee: PropTypes.bool,
  }).isRequired,
};
